import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "../styles/style.js";
import Headings from "./Headings.js";

const OurServices = () => {
  const data = useStaticQuery(graphql`
    query {
      smallImageServices: allFile(
        filter: {
          name: {
            in: [
              "07-power-sockets-electrical-installations"
              "06-eicr-pat-services"
              "fuseboards-03"
              "08-biffa-roller-shutter"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      largeImageServices: allFile(
        filter: {
          name: {
            in: [
              "04-industrial-electrical-wiring-installation"
              "02-unknown-project-commercial-lighting"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);
  //console.log("data: ", data);

  const installations = data.smallImageServices.nodes.find((image) => {
    return image.name === "07-power-sockets-electrical-installations";
  });

  const eicrPat = data.smallImageServices.nodes.find((image) => {
    return image.name === "06-eicr-pat-services";
  });

  const wiringServices = data.largeImageServices.nodes.find((image) => {
    return image.name === "04-industrial-electrical-wiring-installation";
  });

  const lightingSolutions = data.largeImageServices.nodes.find((image) => {
    return image.name === "02-unknown-project-commercial-lighting";
  });

  const fuseBoard = data.smallImageServices.nodes.find((image) => {
    return image.name === "fuseboards-03";
  });

  const securitySolutuions = data.smallImageServices.nodes.find((image) => {
    return image.name === "08-biffa-roller-shutter";
  });

  return (
    <section
      id="our-services-gallery"
      className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <Headings
          titleTop="Our range"
          titleBottom="of services"
          description="At Intact Electrical, we provide comprehensive electrical services. Our expert workforce has experience in all areas of electrical installations. Whatever you need we have you covered."
        />
        <div className="lg:flex items-stretch  mt-4 mb-8">
          <div className="lg:w-1/2">
            <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6">
              <div className="sm:w-1/2 relative">
                <div className="rounded">
                  {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                    Commercial & Domestic
                  </p> */}
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10 "
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 p-6 z-10">
                    <h2 className="text-xl text-white">
                      Electrical installations
                    </h2>
                    <p className="text-base leading-4 text-white mt-2">
                      sockets & fittings
                    </p>
                    <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                          to="/services/electrical-installations"
                          alt="electrical installation services"
                          aria-label="electrical installation services"
                        >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <GatsbyImage
                  image={installations.childImageSharp.gatsbyImageData}
                  alt={installations.name}
                  className="w-full"
                  loading="lazy"
                />
              </div>
              <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
                <div>
                  {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                    Commercial & Domestic
                  </p> */}
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 p-6 z-10">
                    <h2 className="text-xl text-white">EICR & PAT</h2>
                    <p className="text-base leading-4 text-white mt-2">
                      health & safety
                    </p>
                    <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                          to="/services/eicers-and-pat-testing"
                          alt="eirc and pat testing health and saftey"
                          aria-label="eirc and pat testing health and saftey"
                        >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <GatsbyImage
                  image={eicrPat.childImageSharp.gatsbyImageData}
                  alt={installations.name}
                  className="w-full"
                  loading="lazy"
                />
                {/* <img
                  src="https://i.ibb.co/3C5HvxC/img-2.png"
                  className="w-full"
                  alt="wall design"
                /> */}
              </div>
            </div>
            <div className="relative">
              <div>
                {/* <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                  Commercial & Domestic
                </p> */}
                <div
                  className="absolute left-0 bottom-0 w-full h-full z-10"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                  }}
                ></div>
                <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                  <h2 className="text-xl text-white">Wiring services</h2>
                  <p className="text-base leading-4 text-white mt-2">
                    full or partial
                  </p>
                  <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                    <p className="pr-2 text-sm font-medium leading-none">
                      <Link
                          to="/services/wiring-services"
                          alt="Electrical wiring services link"
                          aria-label="Electrical wiring services link"
                        >
                          Read More
                      </Link>
                    </p>
                    <svg
                      className="fill-stroke"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75 12.5L10.25 8L5.75 3.5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <GatsbyImage
                image={wiringServices.childImageSharp.gatsbyImageData}
                alt={wiringServices.name}
                className="w-full mt-8 md:mt-6"
                loading="lazy"
                style={{ maxHeight: "540px" }}
              />
              {/* <img
                src="https://i.ibb.co/Ms4qyXp/img-3.png"
                alt="sitting place"
                className="w-full mt-8 md:mt-6"
                //className="w-full mt-8 md:mt-6 hidden sm:block"
              /> */}
              {/* <img
                className="w-full mt-4 sm:hidden"
                src="https://i.ibb.co/6XYbN7f/Rectangle-29.png"
                alt="sitting place"
              /> */}
            </div>
          </div>
          <div className="lg:w-1/2 xl:ml-8 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
            <div className="relative">
              <div>
                {/* <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                  Commercial & domestic
                </p> */}
                <div
                  className="absolute left-0 bottom-0 w-full h-full z-10"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                  }}
                ></div>
                <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                  <h2 className="text-xl text-white">Lighting solutions</h2>
                  <p className="text-base leading-4 text-white mt-2">
                    internal & external
                  </p>
                  <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                    <p className="pr-2 text-sm font-medium leading-none">
                      <Link
                          to="/services/lighting-services"
                          alt="Electrical lightintg services link"
                          aria-label="Electrical lighting services link"
                        >
                          Read More
                      </Link>
                    </p>
                    <svg
                      className="fill-stroke"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75 12.5L10.25 8L5.75 3.5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <GatsbyImage
                image={lightingSolutions.childImageSharp.gatsbyImageData}
                alt={lightingSolutions.name}
                className="w-full"
                loading="lazy"
                style={{ maxHeight: "540px" }}
              />
              {/*  <img
                src="https://i.ibb.co/6Wfjf2w/img-4.png"
                alt="sitting place"
                className="w-full"
                //className="w-full sm:block hidden"
              /> */}
              {/* <img
                className="w-full sm:hidden"
                src="https://i.ibb.co/dpXStJk/Rectangle-29.png"
                alt="sitting place"
              /> */}
            </div>
            <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6 md:mt-6 mt-4">
              <div className="relative w-full">
                <div>
                  {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                    Commercial & Domestic
                  </p> */}
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 p-6 z-10">
                    <h2 className="text-xl text-white">Fuse boards</h2>
                    <p className="text-base leading-4 text-white mt-2">
                      upgrades & testing
                    </p>
                    <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                          to="/services/fuse-boards"
                          alt="Fuse boards services link"
                          aria-label="Fuse boards services link"
                        >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <GatsbyImage
                  image={fuseBoard.childImageSharp.gatsbyImageData}
                  alt={fuseBoard.name}
                  className="w-full"
                  loading="lazy"
                />
                {/* <img
                  src="https://i.ibb.co/3yvZBpm/img-5.png"
                  className="w-full"
                  alt="chair"
                /> */}
              </div>
              <div className="relative w-full sm:mt-0 mt-4">
                <div>
                  {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
                    Commercial & Domestic
                  </p> */}
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 p-6 z-10">
                    <h2 className="text-xl text-white">Security solutions</h2>
                    <p className="text-base leading-4 text-white mt-2">
                      peace of mind
                    </p>
                    <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                          to="/services/security-solutions"
                          alt="Security solutions services link"
                          aria-label="Security solutions services link"
                        >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <GatsbyImage
                  image={securitySolutuions.childImageSharp.gatsbyImageData}
                  alt={securitySolutuions.name}
                  className="w-full"
                  loading="lazy"
                />

                {/* <img
                  src="https://i.ibb.co/gDdnJb5/img-6.png"
                  className="w-full"
                  alt="wall design"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
